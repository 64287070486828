<h2>Settings</h2>
<table class="p-4">
  <tr>
    <td>Remember settings</td>
    <td>
      <mat-slide-toggle
        [checked]="settings.remember()"
        (change)="settings.updateRemember($event.checked)"
      ></mat-slide-toggle>
    </td>
  </tr>
  <tr>
    <td>Dark mode</td>
    <td>
      <mat-slide-toggle
        [checked]="settings.darkMode()"
        (change)="settings.updateDarkMode($event.checked)"
      ></mat-slide-toggle>
    </td>
  </tr>
  <tr>
    <td>Router tracing</td>
    <td>
      <mat-slide-toggle
        [checked]="settings.routerTracing()"
        (change)="settings.updateRouterTracing($event.checked)"
      ></mat-slide-toggle>
    </td>
  </tr>
  <tr>
    <td>Log Build info</td>
    <td>
      <mat-slide-toggle
        [checked]="settings.buildInformationLog()"
        (change)="settings.updateBuildInformationLog($event.checked)"
      ></mat-slide-toggle>
    </td>
  </tr>
</table>
