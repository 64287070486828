import {
  assertInInjectionContext,
  computed,
  inject,
  isDevMode,
  signal,
} from '@angular/core';
import { DevSettingStore } from './dev-settings/dev-settings.store';
import { DevStoreMonitorStore } from './dev-store-monitor/dev-store-monitor.store';

const devMenu = signal(false);

export const devMenuEnabled = computed(() => devMenu() && isDevMode());

export function enableDevMenu() {
  isDevMode() && devMenu.set(true);
}

/**
 * By injecting the store instances will be constructed and start working
 * This function serves as a way to start these without exposing the stores as public_api
 */
export function startDevTools() {
  assertInInjectionContext(startDevTools);

  inject(DevSettingStore, {
    optional: true,
  });

  inject(DevStoreMonitorStore, {
    optional: true,
  });
}
